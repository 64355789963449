<template>
  <div class="CopiedFromOrderBanner type-xs text-lightest">
    <div class="flex justify-center items-center bg-purplePrimary h-32">
      <div class="flex flex-row flex-wrap gap-8 justify-between w-full px-12">
        <div>
          {{ $t('siteNotice.editingOrder') }} #{{ orderId }}
        </div>
        <div class="btn--text btn--sm btn--inv" @click="clearEditingOrder">
          {{ $t('checkout.orderProposal.cancel') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '~/store/cart';
import { useUiStore } from '~/store/ui';

const cartStore = useCartStore();
const uiStore = useUiStore();

const emit = defineEmits(['closeMenu']);

defineProps<{
  orderId: string;
}>();

const clearEditingOrder = async() => {
  cartStore.deleteCart();
  uiStore.closeAllModal();
  emit('closeMenu');
};

</script>
